import React, { Component } from "react"

import DatosAdicionales from './DatosAdicionales'
import { SelectIcon, ButtonDegrade, CardElevation, TitleIcon, MaterialInput } from '@findep/mf-landings-core'
import AddComment from '@material-ui/icons/AddComment';
import AttachMoney from '@material-ui/icons/AttachMoney';
import PersonAdd from '@material-ui/icons/PersonAdd';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Home from '@material-ui/icons/Home';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { TextField, CircularProgress, Backdrop, Snackbar } from "@material-ui/core"
// import { , , , , , , , Box, Container, Radio, RadioGroup, , FormLabel } from "@material-ui/core"
import Grid from '@material-ui/core/Grid';
import { css } from '@emotion/react'
import { scoreBFF } from '../../lib/saveData'
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';

const root = css`
    width: 95%;
    display: flex;
`

const iconRoot = css`
    margin-right: 0.1rem;
    color: rgba(0,0,0,0.26);
    font-size: 2.2rem !important;
    margin-left: 0.2rem;
    margin-top: 0.3rem;
`

const containerSelct = css`
    display: flex;
    flex-direction: column;
    width: 100% !important;
        
`
const titulo = css`
    text-align: center;
`

const backDropCss = css`
    z-index: 1000;
`


class DatosAdicionalesPensionados extends DatosAdicionales {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            btnNextEnable: false,
        };
    }

    eventoButton = () => {
        this.setState({ loading: true })
        scoreBFF(this, this.state, true)
    }

    areAllFieldsValid = () => {
        const {
            civilselect,
            monthlyincome,
            dependentsselect,
            timehome,
        } = this.state.data;
        return (
            (civilselect !== '' && civilselect !== null) &&
            (monthlyincome !== '' && monthlyincome !== null) &&
            (dependentsselect !== '' && dependentsselect !== null) &&
            (timehome !== '' && timehome !== null && timehome !== 0)
        );
    };

    componentDidUpdate(prevProp, prevState) {
        if (this.state.data !== prevState.data) {
            const allFieldsValid = this.areAllFieldsValid();
            if (allFieldsValid !== prevState.btnNextEnable) {
                this.setState({ btnNextEnable: allFieldsValid });
            }
        }
    }

    render() {

        const civilSelectConf = { label: 'Estado civil', altText: 'Selecciona tu estado civil', icon: AccountCircle }
        const monthlyIncomeConf = { label: '¿Cuánto ganas?', altText: 'Comprobable mensual', icon: <AttachMoney />, iconAlt: <ErrorOutline /> }
        const expensesTextConf = { label: '¿Cuánto gastas?', altText: 'Estimado mensual', icon: <AttachMoney />, iconAlt: <ErrorOutline /> }
        const dependentsConf = { label: 'Dependientes económicos', altText: '', icon: PersonAdd }
        const timeHomeConf = { label: 'Tiempo en tu domicilio actual', altText: '', icon: Home }
        const { monthlyincome, expensestext } = this.state.data
        const { dataCivil, dataDependents, dataTimeHome, btnNextEnable, headerless } = this.state
        return (
            <>
                {headerless &&
                    <>
                        <Grid container justify="center">
                            <Grid item style={{ textAlign: "center" }}>
                                <TitleIcon css={titulo} icon={AddComment} title='Datos adicionales' textColor />
                            </Grid>
                        </Grid>
                        <Grid container justify="center">
                            <Grid item style={{ textAlign: "center", color: 'red' }}>
                                <h2>Ya estás muy cerca de obtener una respuesta</h2>
                            </Grid>
                        </Grid>

                        <h4>Ingresa sólo unos datos más para obtener tu crédito inmediato</h4>
                    </>
                }
                <CardElevation noPadding>
                    <br />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} style={{ paddingRight: '25px' }}>
                            <SelectIcon
                                id="datos-adicionales-estado-civil"
                                fullWidth sizeIcon="24px"
                                options={dataCivil}
                                onChange={(event) => this.handleChange(event, 'civilselect')}
                                label={civilSelectConf.label} icon={civilSelectConf.icon}
                                altText={civilSelectConf.altText}
                                value={this.state.data.civilselect}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <div css={root}>
                                <span style={{ color: monthlyincome !== '' && 'black' }} css={iconRoot}>{monthlyIncomeConf.icon}</span>
                                <div css={containerSelct}>
                                    <TextField
                                        id="datos-adicionales-monthlyincome-input"
                                        label={monthlyIncomeConf.label}
                                        helperText={monthlyIncomeConf.altText}
                                        variant="outlined"
                                        name='monthlyincome'
                                        onChange={(e) => this.handleInput(e)}
                                        value={monthlyincome}
                                        fullWidth={true}
                                        type="number"
                                    />
                                </div>
                                <Tooltip title="Recuerda que este ingreso mensual debe ser comprobable." placement="left" arrow>
                                    <span css={iconRoot}>{monthlyIncomeConf.iconAlt}</span>
                                </Tooltip>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <div css={root}>
                                <span style={{ color: expensestext !== '' && 'black' }} css={iconRoot}>{expensesTextConf.icon}</span>
                                <div css={containerSelct}>
                                    <TextField
                                        id="datos-adicionales-expensestext-input"
                                        label={expensesTextConf.label}
                                        helperText={expensesTextConf.altText}
                                        variant="outlined"
                                        name='expensestext'
                                        onChange={(e) => this.handleInput(e)}
                                        value={expensestext}
                                        fullWidth={true}
                                        type="number"
                                    />
                                </div>
                                <Tooltip title="Los gastos familiares pueden ser en escuela, útiles escolares, ropa, despensa, etc." placement="left" arrow>
                                    <span css={iconRoot}>{expensesTextConf.iconAlt}</span>
                                </Tooltip>
                            </div>

                        </Grid> */}
                        <Grid item xs={12} md={4} style={{ paddingRight: '25px' }}>
                            <SelectIcon
                                id="datos-adicionales-dependents"
                                fullWidth
                                sizeIcon="24px"
                                options={dataDependents}
                                onChange={(event) => this.handleChange(event, 'dependentsselect')}
                                label={dependentsConf.label}
                                icon={dependentsConf.icon}
                                altText={dependentsConf.altText}
                                value={this.state.data.dependentsselect}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={4} style={{ paddingRight: '25px' }}>
                            <SelectIcon
                                id="datos-adicionales-timehome"
                                fullWidth
                                sizeIcon="24px"
                                options={dataTimeHome}
                                onChange={(event) => this.handleChange(event, 'timehome')}
                                label={timeHomeConf.label}
                                icon={timeHomeConf.icon}
                                altText={timeHomeConf.altText}
                                value={this.state.data.timehome}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                            <ButtonDegrade id="datos-adicionales-continuar-button" disabled={!btnNextEnable} textButton='GUARDAR DATOS Y CONTINUAR' onClick={this.eventoButton} />
                        </Grid>
                    </Grid>
                    <br />
                </CardElevation>
                <br />
                <Backdrop open={this.state.loading} css={backDropCss}>
                    <CircularProgress color="primary" />
                </Backdrop>
                <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
                        No se permite usar el mismo número en ambos campos
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.errorEndPoint} autoHideDuration={6000} onClose={() => this.setState({ errorEndPoint: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorEndPoint: false })}>
                        Lo sentimos, por el momento el servicio no se encuentra disponible
                    </Alert>
                </Snackbar>
            </>
        )
    }
}
export default DatosAdicionalesPensionados